<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="display: flex; justify-content: space-around; padding: 30px">
        <van-button type="primary" @click="search()">查询</van-button>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{dataList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>用户名</th>
              <th>sn</th>
              <th>deeperChain</th>
              <th>入网时间</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item._id">
              <td>{{ item.user }}</td>
              <td>{{item.sn}}</td>
              <td>{{item.deeperChain}}</td>
              <td>{{$moment(item.createdAt).format()}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
import Vue from 'vue';
import jwt from 'jsonwebtoken'

export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      dataList: []
    };
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList(search) {
      return this.$fetch({
        url: "/admin/addToDeeperChainRecord",
        params: {
          search
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.dataList = res.data;
      }).finally(() => {
      })
    },
    search() {
      let search = prompt('请输入搜索内容')
      this.getDataList(search)
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>